import {deleteCategory, deleteClauses, editClauses, getClauses, uploadPatent} from "./redux/actions";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

// export const BASE_URL = 'https://staging-api.legalup.me';
// export const SERVER_URL = 'https://staging-api.legalup.me';
// export const ADMIN_URL = 'https://staging-api.legalup.me';

async function postApi(path, body, bearer) {
    const strBody = typeof body === 'string' ? body : JSON.stringify(body);
    const useBearer = bearer === false ? false : true;
    const params = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
//        mode: 'cors',
        body: strBody
    };
    if (useBearer) params.headers['Authorization'] = `BEARER ${localStorage.getItem('access_token')}`;
    const data = await fetch(`${BASE_URL}${path}`, params);    
    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        console.log('ERROR:', data);
        console.log('ERRORresponse:', response);
        const error = new Error(data.statusText);
        error.code = data.status;
        error.errorMessage = response.message;
        throw error;
    }
}

async function postApiWithFormData(path, formData, bearer = true) {
    const params = {
        headers: {
            'Accept': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: formData
    };

    if (bearer) {
        params.headers['Authorization'] = `BEARER ${localStorage.getItem('access_token')}`;
    }

    const data = await fetch(`${BASE_URL}${path}`, params);
    const response = await data.json();

    if (data.status === 200) {
        return response.result;
    } else {
        console.error('ERROR:', data);
        console.error('ERROR response:', response);
        const error = new Error(data.statusText);
        error.code = data.status;
        error.errorMessage = response.message;
        throw error;
    }
}


async function patchApi(path, body) {
    const strBody = typeof body === 'string' ? body : JSON.stringify(body);
    console.log('patching body ' + strBody.length + ' bytes long', strBody);
    const data = await fetch(`${BASE_URL}${path}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `BEARER ${localStorage.getItem('access_token')}`
        },
        credentials: 'include',
        method: 'PATCH',
//        mode: 'cors',
        body: strBody
    });
    
    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

async function getApi(path, query) {
    const queryPart = query !== undefined ? `?${query}`: '';
    const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `BEARER ${localStorage.getItem('access_token')}`,
            'Cache-Control': 'no-cache'
        },
        credentials: 'include',
//        mode: 'cors',
        method: 'GET'
    });
    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(response.message);
        error.code = data.status;
        throw error;
    }
}

async function deleteApi(path, query) {
    const queryPart = query !== undefined ? `?${query}`: '';
    const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `BEARER ${localStorage.getItem('access_token')}`
        },
        credentials: 'include',
//        mode: 'cors',
        method: 'DELETE'
    });
    const response = await data.json();
    console.log('got after get:', response);
    if (data.status === 200) {
        return response.result;
    } else {
        console.log({data});
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

export class Users {
    async authenticate(email, password) {
        const body = { email: email.toLowerCase(), password }; 
        const user = await postApi('/users/authenticate', body);
        localStorage.setItem('current_user', JSON.stringify(user));
        localStorage.setItem('access_token', user.access_token)
        return user;
    }

    async create(email, password) {
        const body = { email: email.toLowerCase(), password, username: email.toLowerCase()};
        const user = await postApi('/users',  body, false);
        localStorage.setItem('current_user', JSON.stringify(user));
        localStorage.setItem('access_token', user.access_token)
        return user;
    }
    
    async forgotPassword(email) {
        const a = await postApi('/users/forgotPassword', {email});
        return a;
    }
    
    async resetPassword(email, token, password) {
        const a = await postApi('/users/resetPassword', { email, token, password });
        return a;
    }
}

export class Documents {
    async createDocument(body) {
        const document = await postApi('/documents/', body);
        return document;
    }
    
    async updateDocument(id, body) {
        const document = await patchApi(`/documents/${id}/`, body);
        return document;
    }

    async getDocument(id, singleClauseId) {
        let url = `/documents/${id}`;

        // when fetching the document for the alternatives view we use the singleClauseId
        // this causes the server to filter out only the single relevant clause in the result
        if (singleClauseId) {
            url += `?single_clause_id=${singleClauseId}`;
        }

        const document = await getApi(url);
        return document;
    }

    async getDocumentDocx(id) {
        const docx = await getApi(`/documents/${id}/docx`);
        return docx;
    }

    async getClauseDocx(documentId, clauseId) {
        const docx = await getApi(`/documents/${documentId}/clauses/${clauseId}/docx`);
        return docx;
    }

    async getClause(clauseId, documentId) {
        const clause = await getApi(`/documents/${documentId}/clauses/${clauseId}/`);
        return clause;
    }
    
    async updateClause(clauseId, documentId, body) {
        return patchApi(`/documents/${documentId}/clauses/${clauseId}`, body);
    }

    async deleteClauseAlternative(clauseId, documentId, alternativeId) {
        return deleteApi(`/documents/${documentId}/clauses/${clauseId}/alternatives/${alternativeId}`);
    }
    
    async updateClauseAlternatives(clauseId, documentId, rawData) {
        return postApi(`/documents/${documentId}/clauses/${clauseId}/alternatives`, {
            raw_data: rawData
        });
    }

    async getDocuments(sort, pageSize, pageIndex, is_checklist) {
        return getApi(`/documents?sort=${sort || '-updated_at'}&page_size=${pageSize || 100 }&page_index=${pageIndex || 0}${is_checklist ? '&is_checklist=true': ''}`);
    }

    async ensureQuestion(documentId, body) {
        return postApi(`/documents/${documentId}/questions/`, body);
    }

    async updateQuestion(documentId, questionId, body) {
        return patchApi(`/documents/${documentId}/questions/${questionId}/`, body);
    }

    async deleteQuestion(documentId, questionId) {
        return deleteApi(`/documents/${documentId}/questions/${questionId}/`);
    }

    async getQuestions(documentId) {
        return getApi(`/documents/${documentId}/questions/`);
    }

    async getQuestion(documentId, questionId) {
        return getApi(`/documents/${documentId}/questions/${questionId}/`);
    }

    async getPhrase(documentId, clauseId, phraseId) {
        return getApi(`/documents/${documentId}/clauses/${clauseId}/phrases/${phraseId}/`);
    }

    async updatePhrase(documentId, clauseId, phraseId, body) {
        return patchApi(`/documents/${documentId}/clauses/${clauseId}/phrases/${phraseId}/`, body);
    }

    async deletePhrase(documentId, clauseId, phraseId) {
        return deleteApi(`/documents/${documentId}/clauses/${clauseId}/phrases/${phraseId}/`);
    }
    
    async deleteDocument(docId) {
        return deleteApi(`/documents/${docId}`);
    }

    async copyDocument(docId) {
        return getApi(`/documents/${docId}/clone`);
    }

    async switchQuestions(docId, id1, id2) {
        return patchApi(`/documents/${docId}/switch_questions`, {document_id: docId, question_id1: id1.toString(), question_id2: id2.toString()});
    }

    async askAi(body) {
        return postApi(`/ai`, body);
    }

    async findPatents(text) {
        return getApi(`/ai/patents?text=${text}`);
    }
    async uploadPatent(formData) {
        return postApiWithFormData(`/ai/patents/text`, formData);
    }

}


export class Organization {

    async getOrganization(id) {
        let url = `/organizations/${id}`;

        const organization = await getApi(url);
        return organization;
    }

    async getCategory() {
        return getApi(`/document-categories/`);

    }

    async createCategory(category) {
        return postApi('/document-categories/', category);
    }

    async deleteCategory(id) {
        return deleteApi(`/document-categories/${id}`);
    }
}

export class Files {
    async createDocx(documentId, answers) {
        const answersArray = [];
        for (let i = 0; i < answers.length; ++i) {
            const answer = answers[i];
            if (Array.isArray(answer)) {
                for (let j = 0; j < answer.length; ++j) {
                    answersArray.push(answer[j]);
                }
            } else {
                answersArray.push(answer);
            }
        }
        const body = { document_id: documentId, answers: answersArray };
        return postApi(`/files/`,  body);
    }
}

export class Taxonomy {
    async createDocumentType(data) {
        return postApi('/taxonomy/documentTypes', data);
    }

    async updateDocumentType(id, name, description) {
        return patchApi(`/taxonomy/documentTypes/${id}`, { name, description });
    }

    async deleteDocumentType(id) {
        return deleteApi(`/taxonomy/documentTypes/${id}`);
    }

    async getDocumentType(id) {
        return getApi(`/taxonomy/documentTypes/${id}`);
    }

    async getDocumentTypeByLanguage(leng) {
        return getApi(`/taxonomy/documentTypes?language=${leng}`);
    }

    async clauseCategories(leng) {
        return getApi(`/taxonomy/clauseCategories?language=${leng}&page_size=99999`);
    }

    async createClause(data) {
        return postApi('/clauses', data);
    }


    async searchDocumentTypes(prefix) {
        return postApi('/taxonomy/documentTypes/search', { prefix });
    }

    async createClauseCategory(name, description, documentTypeIds, parentId) {
        return postApi('/taxonomy/clauseCategories', { 
            name,
            description,
            document_type_ids: documentTypeIds,
            parent_id: parentId
        });
    }

    async updateClauseCategory(id, name, description, documentTypeIds, parentId) {
        return patchApi(`/taxonomy/clauseCategories/${id}`, { 
            name,
            description,
            document_type_ids: documentTypeIds,
            parent_id: parentId
        });
    }

    async deleteClauseCategory(id) {
        return deleteApi(`/taxonomy/clauseCategories/${id}`);
    }

    async getClauseCategory(id) {
        return getApi(`/taxonomy/clauseCategories/${id}`);
    }

    async searchDocumentTypes(prefix, documentTypeId, parentId) {
        return postApi('/taxonomy/clauseCategories/search', { 
            prefix,
            document_type_id: documentTypeId,
            parent_id: parentId
        });
    }

    async getClauses(text) {
        return getApi(`/clauses?text=${text}&page_size=99999`);
    }

    async deleteClauses(id) {
        return deleteApi(`/clauses/${id}`);
    }

    async editClauses(id, data) {
        return patchApi(`/clauses/${id}`, data);
    }


}

const def = {
    users: new Users(),
    documents: new Documents(),
    files: new Files(),
    taxonomy: new Taxonomy(),
    organization: new Organization(),
};
export { def as default };
